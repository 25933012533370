/* @import url("https://fonts.googleapis.com/css?family=Open+Sans"); */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}

.submit-form {
  max-width: 300px;
  margin: auto;
}

.edit-form {
  max-width: 300px;
  margin: auto;
}

.card-img-top {
  width: 100%;
  height: 12vw;
  object-fit: cover;
}

@media (min-width: 1200px) {
  .container{
      max-width: 1500px;
  }
}

#colorstrip{
  width: 100%; height: 20px;
  border-style: solid;
  border-color: white;
  background-color: white;
}

hr {
  border: 0;
  clear:both;
  display:block;
  width: 96%;               
  background-color:grey;
  height: 1px;
}

.input-tags {
  width: 100%;
  padding: 15px;
  display: block;
  margin: 0 auto;
}

.label-info {
  background-color: #5bc0de;
  padding: 3px;
}


.bootstrap-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  display: block;
  padding: 4px 6px;
  color: #555;
  vertical-align: middle;
  border-radius: 4px;
  max-width: 100%;
  line-height: 22px;
  cursor: text;
}
.bootstrap-tagsinput input {
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  padding: 0 6px;
  margin: 0;
  width: auto;
  max-width: inherit;
}

.main::before {  
    content: "";
    background-image: url("https://wallpaper.dog/large/20477005.png");
    background-attachment: "fixed";
    background-size: "cover";
    min-height: '100vh';
    flex: 1;
    background-repeat: 'repeat';
    opacity: 1.0;
}

/* .nav-tabs{
  background-color:#161616;
} */

::-webkit-scrollbar {
  display: none;
  width: 8px;
}

::-webkit-scrollbar-track{
  background: #b3b3b3;
}

::-webkit-scrollbar-thumb{
  background: #666666;
  border-radius: 5px;
}


.card-img-top {
  width: 100%;
  height: 8vw;
  object-fit: cover;
}

.image {
  position: relative;
  width: 280px;
  height: 200px;
  display: flex;
  align-items: top;
  justify-content: center;
}

.image__img {
  display: block;
  width: 100%;
  height: 100%;
}

.image__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: #ffffff;
  font-family: 'Quicksand',sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.25s;
}

.image__overlay--blur {
  backdrop-filter: blur(5px);
}

.image__overlay > * {
  transform: translateY(20px);
  transition: transform 0.25s;
}

.image__overlay:hover {
  opacity: 1;
  width: 275px;
}

:root {
  --main-bg-color: #b6981c;
  /* --main-bg-color: #2cc2d3; */
  /* --main-bg-color:  #c07e25; */
}

.icon__initial{
    /* color: #c07e25; */
    color:var(--main-bg-color);
    width: 40%;
    height: 40%; 
    position: absolute; 
    top: 20%;
}

.icon__initial__text{
  text-align: center; 
  color: white; 
  width: 100%;
  position: absolute; 
  top: 65%; 
  font-family: 'Quicksand',sans-serif; 
  font-size: 1.3em;
}

.icon__initial__text_archiv{
  text-align: center; 
  color: white; 
  width: 100%;
  position: absolute; 
  top: 60%; 
  font-family: 'Quicksand',sans-serif; 
  font-size: 1.3em;
}

.card:hover{
border-color: var(--main-bg-color);
border-width: 3px;
}
.image__overlay:hover > * {
  transform: translate(0);
}

.image__title {
  font-size: 1.5em;
  font-weight: bold;
}

.image__description {
  font-size: 1.em;
  margin-top: 1.0em;
  margin-left: 0.0em;
  width: 85%;
}

.right-card {
  flex: 0 0 50%;
  display: flex;
  justify-content: flex-end;
}

.left-card {
  flex: 0 0 50%;
  display: flex;
  justify-content: flex-start;
}


.custom-file-label::after {
  content: "Vyhľadať" !important; }


  body {
    font-family: 'Open Sans';
  }

  .required:before {
    content:" *";
    color:white;
  }

  .btn-custom, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary{
    color:white;
    border-width: 4px;
    border-top-left-radius: 5px;
    /* background-color: #00b3db; */
    border-color: #030f08; 
}

  .btn-custom:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
    color: rgb(156, 148, 148);
    border-width: 0px;
    padding:10px;
    background-color: #00b3db;
    border-color: #030f08; 
}

.btn-custom-scans, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
  color: rgb(253, 251, 251);
  border-width: 3px;
  /* background-color: #696665; */
  border-radius: 5px;
  margin-left: 5px;
  /* border-color: #221229; set the color you want here */
  border-color: black; 
  /* border-top-color: rgb(94, 89, 89);  */
  /* border-bottom-color: rgb(94, 89, 89);  */
}

.btn-custom-scans:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
  color: rgb(156, 148, 148);
  background-color: #38413e;
  /* border-color: #221229; set the color you want here */
  border-color: black; 
}

.form-check-input-custom {
  width: 20px;
  height: 20px;
  margin-right: 3px;
}



input:disabled, select, textarea{
  color: #929191;
}

.navbar-header{
  margin-left:5px;
  width:100%;
}

/* login page related CSS */
.head {
}
.background {
  display: grid;
  height: 100vh;
  background-color: black;
  justify-content: center;
}

.header{
  text-align: center;
  margin-bottom: 0em;
  border-bottom: 0em;
  padding-bottom: 0em;

}

.text {
  color: grey;
  padding-bottom: 0px;
  font-size: 35px;
  font-weight: 300;
  ;
}

.box {
  height: 50vh;
  background-color: #19242a;
  width: 450px;
  margin-bottom: 135px;
  border-radius: 20px;
  max-width: 500;
  max-height: 1200px;
}

.login-text {
  color: white;
  margin-top: 10;
}

#IdInput {
  border: 0px solid black;
  border-radius: 10px;
  margin-top: 55px;
  padding: 20px;
  background-color: #ffffff;
  color: #000000;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 17px;
  width: 250px;
  height: 50px;
  transition: ease-in-out, width 0.35s ease-in-out;
}

#IdInput:hover {
  width: 300px;
}

#IdInput:focus {
  width: 300px;
}

#passwordInput {
  border: 2px solid black;
  border-radius: 10px;
  margin-top: 50px;
  padding: 20px;
  background-color: #ffffff;
  color: #000000;
  font-weight: bold;
  font-size: 17px;
  font-family: sans-serif;
  width: 250px;
  height: 50px;
  transition: ease-in-out, width 0.35s ease-in-out;
}

#passwordInput:hover {
  width: 300px;
}

#passwordInput:focus {
  width: 300px;
}

.login-btn {
  position: relative;
  justify-content: center;
  /* background-color: #09d3ac; */
  background-color: #2b75b9;
  margin-top: 100px;
  border: none;
  border-color: #000;
  border-radius: 24px;
  padding: 12px;
  width: 250px;
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
  font-size: large;
  animation: ease-in-out, width 0.35s ease-out;
  cursor: pointer;
}
.login-btn:hover {
  background-color: #857e7e;
  color: black;
}

#footer-text {
  color: white;
  padding: 10px;
  margin: 0;
}

footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-transform: capitalize;
  font-weight: bold;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: grey;
}

footer:hover {
  background-color: rgb(88, 85, 85);
  padding: 1px;
  color: #ffffff;
  font-weight: bold;
  animation: ease-in-out, width 0.35s ease-out;
  cursor: pointer;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  transition: all 0.4s;
}

.transparent-input {
  background-color: rgba(0, 0, 0, 0);
  border:none;
}

.transparent-input:focus {
  background-color: rgba(0, 0, 0, 0);
  border:none;
}

.kurz-chip {
  background-color: rgb(103, 48, 124,0.5);
  color: white;
  margin: 15px;
  margin-bottom: 0px;
  /* border: 10; */
  padding: 10px;
  padding-right: 20px;
  /* opacity: 0.6; */
  border-radius: 5px;
  border: solid white 1px;
  position: relative;
}

.kurz-chip-head {
  background-color: rgba(94, 90, 95, 0.5);
  color: white;
  margin: 15px;
  margin-bottom: 0px;
  /* border: 10; */
  padding: 10px;
  padding-right: 20px;
  /* opacity: 0.6; */
  border-radius: 5px;
  border: solid white 1px;
  position: relative;
}

.close-custom {
  /* width: 5px; */
  /* height: 5px; */
  margin-left: 3px;
  position: absolute;
  top: -5%;
  right: 2%;
  color: rgb(24, 11, 11)
}

.line-break::after{
  content: "\a";
  white-space: pre;
}

.container-custom {
  columns: 1 auto;
  /* break-inside: avoid-column; */
  flex: 0.5
}

.x ul {
  margin: 0;
  page-break-inside: avoid;           /* Theoretically FF 20+ */
  break-inside: avoid-column;         /* Chrome, Safari, IE 11 */
  display:table;                      /* Actually FF 20+ */
}

.selection-area{
  overflow: scroll;
  max-height: 300px;
  scrollbar-width: "30px";
  /* scroll-behavior: smooth; */
}

::-webkit-scrollbar {
  height: 10px;              /* height of horizontal scrollbar ← You're missing this */
  width: 10px;               /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}

.scroll_custom:hover::-webkit-scrollbar {
  display: block;
  width: 10px;
}


.center-content{
  text-align: center !important;
}

.div-right {
  margin-right: 0;
  margin-left: auto;
  position: relative;
  /* margin-bottom: 0.3cm; */
}

.my-custom-scrollbar {
  position: relative;
  height: 100vh; /* smaller than 100 puts werid additional scrolling within parent element*/
  width: 100%;
  overflow: auto;
  
  }
  .table-wrapper-scroll-y::-webkit-scrollbar {
  display: block;
  /* width:10px */
  }

  .table-wrapper-scroll-x::-webkit-scrollbar {
    display: block;
    /* width: 10px; */
    }

.tableFixHead          { overflow: auto; height: 100px; }
.tableFixHead thead th { 
  position: sticky; 
  top: 0; 
  z-index: 1; 
  opacity: 1; 
  background-color: white;
}

.div-autocomplete{
  position:absolute !important;
}

.span-preukaz-evcislo{
  text-align: left;
  font-size: 18pt;
  font-weight: bold;
  color: blueviolet;
  display: block;
  text-decoration: underline;
  margin-bottom: 0.5em;
}

.badge{
  white-space: unset;
}

.dataTables_scroll{
  max-height: 70vh;
}

.dataTables_scroll::-webkit-scrollbar{
  display: block;
}

.dataTables_scrollHead{
  margin-bottom: -1em;
  padding: 0;
  position: relative;
  /* height: 150px; */
}
.dataTables_scrollHeadInner{
  padding-right: 0px !important;
}

.dataTable_scrollBody{
  max-height: 70vh;
  ;
}
.table-wrapper-scroll-y{
  max-height: none !important;
  max-height: 65vh !important;
}

.dataTables_length{
  color: white;
}

div[data-test~="datatable-entries"]{
  position: fixed;
  margin-top: -3em;
  grid-column: span 8 !important;
}

div[data-test~="datatable-search"]{
  position: fixed;
  margin-top: -3em;
  padding-right: 0%;
  margin-left: 15em;
  /* width: 70vh!important; */
  /* columns: 10px 8; */
  
}

ul[data-test~="pagination"]{
  color: white;
}

.root-div{
  /* position: fixed; */
  /* width: -webkit-fill-available */
}




tbody {
  /* display: block; */
  /* overflow: auto; */
  height: 500px;
  /* width: 100%; */
  background-color: white;
}

/* 
  thead tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

thead, tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
} */

/* thead {
  width: calc( 100% - 1em );
  table-layout: fixed;
} */

/* input[name~="chipInput"]::placeholder{

  content: "Hello there1"!important;
  color: red;
  initial-value: "helllo";
}

input[name~="chipInput"]::-webkit-inline-placeholder{

  content: "Hello";
} */

div[class~="dropdown-heading-value"] > span {
  display: block;
  width: 350px;
}