body {
	color: #333;
	/* font-family: "Helvetica Neue", Arial, sans-serif; */
	font-weight: 200;

  }
  
  .example {
	padding: 0 25px;
  }
 


  
  code {
	padding: .2em .5em;
	font-size: 85%;
	background-color: rgba(0,0,0,0.04);
	border-radius: 3px;
  }
  
  .menu {
	position: absolute;
	box-sizing: border-box;
	width: 100%;
	border: 1px solid #cccccc;
	border-radius: 5px;
	background-color: white;
	z-index: 10;
	overflow: scroll;

  }
  
  .item {
	padding: 2px 6px;
	cursor: default;
	
  }
  
  .item-highlighted {
	color: white;
	background-color: #5394be;
  }
  
  .item-header {
	background-color: #eeeeee;
	color: #454545;
	font-weight: bold;
  }

